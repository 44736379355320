/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'material-theme' as theme;
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.all-legacy-component-themes(theme.$app-theme);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@import 'bootstrap/scss/bootstrap-grid';

// Plus imports for other components in your app.
@import 'table';
@import 'bootstrap-overrides';
@import 'material-overrides';

html,
body {
   height: 100%;
   width: 100%;
}
body {
   margin: 0;
   font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.flex-row {
   display: flex;
   flex-direction: row;
}

.error-snackbar {
   background-color: #ff3333;
}

.icon-18 {
   font-size: 18px;
   line-height: 24px;
}

button.fixed-right-corner {
   position: fixed !important;
   right: 50px;
   bottom: 50px;
}

@media print {
   .no-print,
   .no-print * {
      display: none;
   }
}

#pdf-source * {
   max-width: 1152px;
}

.float-right {
   float: right;
}

p {
   margin-bottom: 0;
}

.mat-checkbox {
   .mat-checkbox-label {
      white-space: normal;
   }

   .mat-checkbox-inner-container {
      margin-top: 4px;
   }
}

.mat-column-options {
   width: 40px;
}

button:not(.mat-icon-button):not(.mat-fab) mat-icon {
   font-size: 20px;
   line-height: 20px;
}

.perf-eval-item {
   .mat-form-field-disabled {
      .mat-input-element {
         color: rgba(0, 0, 0, 0.87);
      }
      .mat-form-field-underline {
         background: none;
      }
   }
}

.mat-form-field.read-only {
   .mat-input-element {
      color: rgba(0, 0, 0, 0.87);
   }
   .mat-form-field-wrapper {
      .mat-form-field-underline {
         background-color: rgba(0, 0, 0, 0.42);
         background-image: none;
      }
   }
   .mat-select-arrow {
      visibility: hidden;
   }
   .mat-select-value {
      color: rgba(0, 0, 0, 0.87);
   }
}

.fr-toolbar .fr-command.fr-btn[id|='imageManager'] {
   display: none;
}

.datepicker-clear {
   position: absolute;
   right: 0px;
}
